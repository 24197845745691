#map {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
}

#instructions {
  position: absolute;
  margin: 20px;
  width: 25%;
  top: 0;
  bottom: 20%;
  padding: 20px;
  background-color: #fff;
  overflow-y: scroll;
  font-family: sans-serif;
}

@keyframes blink {
  50% {
    opacity: 0;
  }
}

.blink {
  animation: blink 1s step-start 0s infinite;
}
.hide {
  display: none !important;
}

.show {
  display: block !important;
  /* display: block; */
}

.modal-y-axis {
  overflow-x: hidden;
  overflow-y: auto;
}

.bg-button {
  background: #fff;
}

.align-table-header {
  align-content: baseline;
}

.chat-message1 {
  word-break: break-word !important; /* Ensures long words break */
  overflow-wrap: break-word !important; /* Ensures long words wrap */
  white-space: pre-wrap !important; /* Preserves whitespace and breaks on new lines */
}

.ul-css {
  list-style-type: none;
  padding: 0;
  border: 1px solid #ddd;
}

.ul-css .li-css {
  padding: 8px 16px;
  border-bottom: 1px solid #ddd;
}

.ul-css .li-css:last-child {
  border-bottom: none;
}

.navlink-custom-border {
  border-right: transparent;
  border-left: transparent;
  border-top: transparent;
  border-bottom: transparent;
  font-size: 16px;
}

.navlink-custom-border-active {
  border-bottom-color: #f69b31 !important;
  border-bottom-width: 2.5px !important;
}

.notification-list {
  list-style: none;
  padding: 0;
  margin: 0;
  overflow: auto;
  max-height: 300px;
}
ul.notification-list > li {
  margin-top: 0;
  background-color: #fff;
  border-bottom: 1px solid #f5f5f5;
  /* overflow:auto;
    max-height:300px; */
}
ul.notification-list > li:last-child {
  border-bottom: none;
}
ul.notification-list > li a {
  display: block;
  padding: 5px;
  border-radius: 2px;
}
ul.notification-list > li a:hover {
  background-color: #fafafa;
}
ul.notification-list > li .list-item {
  border: 0;
  padding: 0;
  position: relative;
}

.notification-list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.noti-details {
  color: #282828;
  margin-bottom: 0;
}

.noti-time {
  font-size: 12px;
  color: #bdbdbd;
  margin: 0;
}

.avatar {
  background-color: #aaa;
  border-radius: 50%;
  color: #fff;
  display: inline-block;
  font-weight: 500;
  height: 41px;
  line-height: 38px;
  margin: 0 10px 0 0;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  vertical-align: middle;
  width: 41px;
  position: relative;
  white-space: nowrap;
}

.main-btn2 {
  display: inline-block;
  font-weight: 500;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border: 0px;
  padding: 0 42px;
  font-size: 18px;
  line-height: 60px;
  border-radius: 5px;
  color: #fff;
  cursor: pointer;
  z-index: 5;
  -webkit-transition: all 0.4s ease-out 0s;
  -moz-transition: all 0.4s ease-out 0s;
  -ms-transition: all 0.4s ease-out 0s;
  -o-transition: all 0.4s ease-out 0s;
  transition: all 0.4s ease-out 0s;
  background-color: #2f2fe9;
}
/* .main-btn2:hover {
    border: 2px solid #2f2fe9;
    background-color: #fff;
} */
.main-btn2:hover {
  color: #fff !important;
}
.user-status-oval {
  border-radius: 25px;
  background-color: #fff;
  border: 1px solid #ccc;
  color: #333;
  padding: 4px 15px;
}
.modal-content-message-chat {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  /* background-color: #fff; */
  background-clip: padding-box;
  /* border: 1px solid rgba(0, 0, 0, 0.2); */
  border-radius: 0.3rem;
  outline: 0;
  margin: 10px 75px;
}
.labels2 {
  color: white;
  background-color: black;
  font-family: "Lucida Grande", "Arial", sans-serif;
  font-size: 12px;
  text-align: center;
  width: auto;
  white-space: nowrap;
  margin-top: -40px;
  padding: 1px 5px 1px 5px;
  /* padding-right: 5px;
    padding-left: 5px; */
}

.marker {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 40px;
  height: 40px;
  background-color: #000;
  border: 2px solid #fff;
  border-radius: 100%;
  user-select: none;
  transform: translate(-50%, -50%);
}
.marker:hover {
  z-index: 1;
}
/* .features-section{
	position: relative;
	padding: 25%;
    width: 100%;
    height: 100%;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
	background-image: url(../images/Ridetifyloginbg.png);
    margin: 0;
} */
.features-section {
  padding: 20%;
  margin: 0;
  background-image: url(../images/Ridetifyloginbg.png);
  background-size: cover;
  height: 100vh;
  background-position: center;
  background-repeat: no-repeat;
}

/* .features-section::before{ */
/* background-color: #fff; */
/* background-position: right; */
/* background-image: url(../images/Ridetifyloginbg.png); */
/* content: ""; */
/* position: absolute; */
/* left: 0; */
/* bottom: 0; */
/* background-repeat: no-repeat; */
/* background-size: contain; */
/* width: 100%; */
/* top: 0; */
/* z-index: -1; */
/* background-size: 100% 100%; */
/* } */

/* body{background:#ecf0f1;}
a{text-decoration:none;} */
.firstLine {
  font-size: 20px;
  font-weight: 300;
}
.secondLine {
  font-size: 32px;
  line-height: 36px;
  font-weight: 600;
}
.thirdLine {
  font-size: 22px;
  line-height: 32px;
  font-weight: 300;
}
.fourthLine {
  font-size: 12px;
  font-weight: 300;
  position: relative;
  top: -10px;
}

.badges {
  position: relative;
  margin: 140px auto;
  width: 200px;
  height: 200px;
  background: #2f2fe9;
  border-radius: 100%;
  color: #fff;
  line-height: 25px;
  text-align: center;
  text-transform: uppercase;
  font-family: "Open Sans", sans-serif;
  -webkit-animation: 3s ease-in-out 0s normal none infinite running swing;
  -moz-animation: 3s ease-in-out 0s normal none infinite running swing;
  -o-animation: 3s ease-in-out 0s normal none infinite running swing;
  animation: 3s ease-in-out 0s normal none infinite running swing;

  -webkit-transform-origin: 100px -71px;
  -moz-transform-origin: 100px -71px;
  -o-transform-origin: 100px -71px;
  transform-origin: 100px -71px;
}
.badges:before {
  content: "";
  position: absolute;
  top: 90px;
  left: 90px;
  width: 20px;
  height: 20px;
  border-radius: 100%;
  -webkit-box-shadow: 0px -82px 0px -2px #fff, 0px -100px #2f2fe9,
    20px -98px #2f2fe9, 39px -94px #2f2fe9, 56px -85px #2f2fe9,
    71px -72px #2f2fe9, 83px -57px #2f2fe9, 93px -40px #2f2fe9,
    98px -20px #2f2fe9, 100px 0px #2f2fe9, -20px -98px #2f2fe9,
    -39px -94px #2f2fe9, -71px -72px #2f2fe9, -56px -85px #2f2fe9,
    -83px -57px #2f2fe9, -93px -40px #2f2fe9, -98px -20px #2f2fe9,
    -100px 0px #2f2fe9, 0px 100px #2f2fe9, -20px 98px #2f2fe9,
    -39px 94px #2f2fe9, -56px 85px #2f2fe9, -71px 72px #2f2fe9,
    -83px 57px #2f2fe9, -93px -40px #2f2fe9, -98px 20px #2f2fe9,
    -93px 40px #2f2fe9, 20px 98px #2f2fe9, 39px 94px #2f2fe9, 56px 85px #2f2fe9,
    71px 72px #2f2fe9, 83px 57px #2f2fe9, 93px 40px #2f2fe9, 98px 20px #2f2fe9;

  -moz-box-shadow: 0px -82px 0px -2px #fff, 0px -100px #2f2fe9,
    20px -98px #2f2fe9, 39px -94px #2f2fe9, 56px -85px #2f2fe9,
    71px -72px #2f2fe9, 83px -57px #2f2fe9, 93px -40px #2f2fe9,
    98px -20px #2f2fe9, 100px 0px #2f2fe9, -20px -98px #2f2fe9,
    -39px -94px #2f2fe9, -71px -72px #2f2fe9, -56px -85px #2f2fe9,
    -83px -57px #2f2fe9, -93px -40px #2f2fe9, -98px -20px #2f2fe9,
    -100px 0px #2f2fe9, 0px 100px #2f2fe9, -20px 98px #2f2fe9,
    -39px 94px #2f2fe9, -56px 85px #2f2fe9, -71px 72px #2f2fe9,
    -83px 57px #2f2fe9, -93px -40px #2f2fe9, -98px 20px #2f2fe9,
    -93px 40px #2f2fe9, 20px 98px #2f2fe9, 39px 94px #2f2fe9, 56px 85px #2f2fe9,
    71px 72px #2f2fe9, 83px 57px #2f2fe9, 93px 40px #2f2fe9, 98px 20px #2f2fe9;

  -o-box-shadow: 0px -82px 0px -2px #fff, 0px -100px #2f2fe9, 20px -98px #2f2fe9,
    39px -94px #2f2fe9, 56px -85px #2f2fe9, 71px -72px #2f2fe9,
    83px -57px #2f2fe9, 93px -40px #2f2fe9, 98px -20px #2f2fe9,
    100px 0px #2f2fe9, -20px -98px #2f2fe9, -39px -94px #2f2fe9,
    -71px -72px #2f2fe9, -56px -85px #2f2fe9, -83px -57px #2f2fe9,
    -93px -40px #2f2fe9, -98px -20px #2f2fe9, -100px 0px #2f2fe9,
    0px 100px #2f2fe9, -20px 98px #2f2fe9, -39px 94px #2f2fe9,
    -56px 85px #2f2fe9, -71px 72px #2f2fe9, -83px 57px #2f2fe9,
    -93px -40px #2f2fe9, -98px 20px #2f2fe9, -93px 40px #2f2fe9,
    20px 98px #2f2fe9, 39px 94px #2f2fe9, 56px 85px #2f2fe9, 71px 72px #2f2fe9,
    83px 57px #2f2fe9, 93px 40px #2f2fe9, 98px 20px #2f2fe9;

  box-shadow: 0px -82px 0px -2px #fff, 0px -100px #2f2fe9, 20px -98px #2f2fe9,
    39px -94px #2f2fe9, 56px -85px #2f2fe9, 71px -72px #2f2fe9,
    83px -57px #2f2fe9, 93px -40px #2f2fe9, 98px -20px #2f2fe9,
    100px 0px #2f2fe9, -20px -98px #2f2fe9, -39px -94px #2f2fe9,
    -71px -72px #2f2fe9, -56px -85px #2f2fe9, -83px -57px #2f2fe9,
    -93px -40px #2f2fe9, -98px -20px #2f2fe9, -100px 0px #2f2fe9,
    0px 100px #2f2fe9, -20px 98px #2f2fe9, -39px 94px #2f2fe9,
    -56px 85px #2f2fe9, -71px 72px #2f2fe9, -83px 57px #2f2fe9,
    -93px -40px #2f2fe9, -98px 20px #2f2fe9, -93px 40px #2f2fe9,
    20px 98px #2f2fe9, 39px 94px #2f2fe9, 56px 85px #2f2fe9, 71px 72px #2f2fe9,
    83px 57px #2f2fe9, 93px 40px #2f2fe9, 98px 20px #2f2fe9;
}
.badges:after {
  content: "";
  position: absolute;
  top: -70px;
  left: 99px;
  width: 2px;
  height: 81px;
  border-radius: 0%;
  background: #000;
}

@-webkit-keyframes swing {
  0% {
    -webkit-transform: rotate(5deg);
  }
  50% {
    -webkit-transform: rotate(-5deg);
  }
  100% {
    -webkit-transform: rotate(5deg);
  }
}
@-moz-keyframes swing {
  0% {
    -moz-transform: rotate(5deg);
  }
  50% {
    -moz-transform: rotate(-5deg);
  }
  100% {
    -moz-transform: rotate(5deg);
  }
}
@-o-keyframes swing {
  0% {
    -o-transform: rotate(5deg);
  }
  50% {
    -o-transform: rotate(-5deg);
  }
  100% {
    -o-transform: rotate(5deg);
  }
}
@keyframes swing {
  0% {
    transform: rotate(5deg);
  }
  50% {
    transform: rotate(-5deg);
  }
  100% {
    transform: rotate(5deg);
  }
}

.circle p {
  border: 4px double #f69b31;
  padding: 15px 0px;
  width: 110px;
  border-radius: 8px;
  text-align: center;
  font-size: 16px;
  font-weight: 600;
}

.payment-price {
  position: absolute;
  left: 65px;
  width: 131px;
  top: 20px;
}

.mile-text {
  border-left: 4px double #f69b31;
  margin-left: 55px;
  margin-top: -16px;
}
.mile-text p {
  writing-mode: vertical-lr;
  font-size: 16px;
  font-weight: 500;
  padding: 10px 0;
}

.mile-text2 {
  border: none;
  margin-left: 10px;
  /* margin-top: -146px; */
  margin-top: -100%;
}
.mile-text2 p {
  writing-mode: vertical-lr;
  font-size: 16px;
  font-weight: 500;
  padding: 10px 0;
}

.col-7.mitbsnav {
  /* margin-left: 180px; */
  margin-left: 20%;
  /* align-items: center ; */
  text-align: center;
}

/* .col-7 {
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
} */

.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center;
  border-radius: 2px;
  /* border-radius: 0 5px 5px 0; */
}

.mitbsnav ul li.active {
  background-color: #f69b31;
}

.mitbsnav ul li:first-child {
  border-radius: 2px;
  /* border-right: none; */
}

.nav-justified .nav-item,
.nav-justified > .nav-link {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center;
}

.mitbsnav ul li {
  border: 1px solid #f69b31;
  padding: 5px 0;
  color: #f69b31;
  background-color: #fff;
  font-size: 14px;
}

.align-items-center {
  align-items: center !important;
}

.mitbsnav ul li a {
  color: #f69b31;
}

.mitbsnav ul li.active a {
  color: #fff;
}

.container-custom {
  position: fixed;
  /* right: 0; */
  left: 0 !important;
  top: 0px !important;
  width: 100% !important;
  /* z-index: 9999; */
  background: #f2f4f7;
  /* margin-left: 240px; */
  /* margin-top: 63px; */
  min-height: 100vh !important;
}

.border-bottom-black {
  border-bottom: 1px solid #808080;
}

.custom-badge {
  background-color: #fc6075;
  color: #fff;
  font-weight: 700;
  position: absolute;
  right: 10px;
  top: -8px;
}

.accordion-button::after {
  display: block;
}

.topnav-dropdown-footer a {
  /* display: block; */
  /* text-align: center; */
  /* color: #333; */
  color: #252d37;
  /* color: #1c4a85; */
  font-size: 13px !important;
}

.active-3 {
  color: #28a2a9 !important;
}
.home-col {
  color: #f69b31 !important;
}

.form-controlnew {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: 0.375rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.form-controlnew:focus {
  color: #212529;
  background-color: #fff;
  border-color: #86b7fe;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
}

.btn-secondarynew {
  /* background: #293E4A; */
  background: #3894bb;
  border-radius: 6px;
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 27px;
  text-transform: uppercase;
  /* color: #F69B31; */
  color: #143443;
}

.btn-secondarynew:hover {
  /* background: #293E4A; */
  background: #3894bb;
  border-radius: 6px;
  /* color: #F69B31; */
  color: #e8edef;
}

.profile-view .pro-edit {
  position: absolute;
  right: 0;
  top: 0;
}
.edit-icon {
  background-color: #eee;
  border: 1px solid #e3e3e3;
  border-radius: 24px;
  color: #606060;
  float: right;
  font-size: 12px;
  line-height: 24px;
  min-height: 26px;
  text-align: center;
  width: 26px;
}
.edit-icon:hover {
  /* background-color: #ff9b44; */
  /* border-color: #ff9b44; */
  color: #fff;
}

.fileupload.btn {
  position: absolute;
  right: 0;
  bottom: 0;
  background: rgba(33, 33, 33, 0.5);
  border-radius: 0;
  padding: 3px 10px;
  border: none;
}
.fileupload input.upload {
  cursor: pointer;
  filter: alpha(opacity=0);
  font-size: 20px;
  margin: 0;
  opacity: 0;
  padding: 0;
  position: absolute;
  right: -3px;
  top: -3px;
  padding: 5px;
}

.profile-img-wrap.edit-img .fileupload.btn {
  left: 0;
}
.profile-img-wrap {
  height: 120px;
  position: absolute;
  width: 120px;
  background: #fff;
  overflow: hidden;
}

.profile-img-wrap img {
  border-radius: 50%;
  height: 120px;
  width: 120px;
}
.profile-img-wrap.edit-img {
  border-radius: 50%;
  /* margin: 0 auto 30px; */
  position: relative;
}
.profile-img-wrap {
  position: relative;
  margin: 0 auto;
}
.profile-view .profile-img-wrap {
  height: 180px;
  width: 120px;
}
.btn-text {
  color: #fff;
}
.inline-block {
  border: solid 1px #b0b0b0;
}

table.table td h2.table-avatar {
  align-items: center;
  display: inline-flex;
  font-size: inherit;
  font-weight: 400;
  margin: 0;
  padding: 0;
  vertical-align: middle;
  white-space: nowrap;
}
table.table td h2.table-avatar.blue-link a {
  color: #007bff;
}

.avatarr {
  /* background-color: #aaa; */
  /* border-radius: 50%; */
  color: #fff;
  display: inline-block;
  font-weight: 500;
  height: 38px;
  line-height: 38px;
  margin: 0 10px 0 0;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  vertical-align: middle;
  width: 38px;
  position: relative;
  white-space: nowrap;
}

.mail-view-title {
  font-weight: 400;
  font-size: 32px;
  margin: 0;
}
.mail-view-action {
  float: right;
}
.mail-sent-time {
  float: right;
}
.sender-name {
  display: block;
  font-weight: 600;
}
.receiver-name {
  color: #777;
}
.mailview-header {
  border-bottom: 1px solid #ddd;
  margin-bottom: 20px;
  padding-bottom: 15px;
}
.mailview-footer {
  border-top: 1px solid #ddd;
  margin-top: 20px;
  padding-top: 15px;
}
.mailview-footer .btn-white {
  min-width: 102px;
}

.btn-white {
  background-color: #fff;
  border: 1px solid #ccc;
  color: #333;
}

.left-action {
  text-align: center;
  margin-bottom: 15px;
}

.pricing-6-item {
  border: 2px solid #293e4a;
  border-radius: 10px;
  box-shadow: 0 1px 5px 0 #293e4a;
  padding: 30px 30px 42px;
}

.pricing-6-item p:after {
  background: transparent;
  border-bottom: 1px dashed #1f72b0;
  content: "";
  display: table;
  height: 1px;
  left: 0;
  margin-top: 20px;
  position: relative;
  width: 100%;
}
.mail-date {
  text-align: right;
}
.unread .name,
.unread .subject,
.unread .mail-date {
  /* color: #000; */
  /* font-weight: 600; */
  background-color: #ddd;
  color: #000;
  font-weight: 700;
  font-family: Arial;
}

.bg-msgs {
  background-color: lightgray !important;
}

.sender-img {
  float: left;
  margin-right: 10px;
  /* width: 40px; */
  border-radius: 50%;
  height: 40px;
  width: 40px;
}

/* CSS used here will be applied after bootstrap.css */

.dropdown3 {
  display: inline-block;
  margin-left: 20px;
  padding: 10px;
}

.glyphicon-bell {
  font-size: 1.5rem;
}

.notifications3 {
  min-width: 420px;
}

.notifications3-wrapper {
  overflow: auto;
  max-height: 250px;
}

.menu-title {
  color: #ff7788;
  font-size: 1.5rem;
  display: inline-block;
}

.glyphicon-circle-arrow-right {
  margin-left: 10px;
}

.notification-heading,
.notification-footer3 {
  padding: 2px 10px;
}

.dropdown3-menu.divider {
  margin: 5px 0;
}

.item-title {
  font-size: 1.3rem;
  color: #000;
}

.notifications3 a.content {
  text-decoration: none;
  background: #ccc;
}

.notification-item3 {
  padding: 10px;
  margin: 5px;
  background: #ccc;
  border-radius: 4px;
}

.activee {
  background-color: black !important;
  border: solid 1px #000 !important;
  color: #fff !important;
}

/* chat css */
.gap-2 {
  gap: 0.5rem !important;
}

.fw-bold {
  font-weight: 700 !important;
}

.me-3-wala-new {
  margin-right: 1rem !important;
}

.card-bodyyy {
  flex: 1 1 auto;
  min-height: 1px;
  padding: 1.25rem;
}

.page-wrapper {
  left: 0;
  margin-left: 230px;
  /* padding-top: 60px; */
  position: relative;
  transition: all 0.2s ease-in-out;
}

.page-wrapper > .content {
  padding: 30px;
}

.page-wrapper.job-wrapper {
  margin-left: 0;
}

/* @media only screen and (min-width: 991px) {
    .page-wrapper {
        margin-left: 60px;
    }
} */

@media only screen and (max-width: 991.98px) {
  .page-wrapper {
    margin-left: 0;
    padding-left: 0;
    padding-right: 0;
  }
  .page-wrapper {
    -webkit-transition: all 0.4s ease;
    -moz-transition: all 0.4s ease;
    transition: all 0.4s ease;
  }
}

@media only screen and (max-width: 575.98px) {
  .page-wrapper > .content {
    padding: 15px;
  }
}

.pad_left {
  padding-left: 1.5rem;
}

.chat-message-cont {
  min-width: 170px !important;
  max-width: 75% !important;
}
.bg-gray {
  background-color: #e9e9e9;
}
.modal-body {
  height: fit-content !important;
}
.group-members-list {
  max-height: 220px;
  overflow-y: auto;
}
.close-new {
  background-color: #fff;
  /* border-radius: 50%; */
  /* color: #fff; */
  font-size: 18px;
  height: 20px;
  line-height: 20px;
  margin: 0;
  opacity: 1;
  padding: 0;
  position: absolute;
  right: 10px;
  top: 10px;
  width: 20px;
  z-index: 99;
}

.settings-box-custom {
  padding: 20px 15%;
}
.cont-bg-new {
  background-color: rgb(20, 52, 67);
  padding: 30px;
  border-radius: 20px;
}

.btn-secondaryyy {
  text-transform: capitalize;
  /* padding: 9px 11px; */
  background-color: #1d82f5 !important;
  color: #fff !important;
  border: 1px solid #1d82f5 !important;
  position: relative;
}

.right-sorting-new {
  float: right;
}

.preloader-container-new {
  /* position: fixed; */
  /* right: 0; */
  left: 0;
  top: 0px;
  /* width: calc(100% - 240px); */
  z-index: 9999;
  /* background: #F2F4F7; */
  /* margin-left: 240px; */
  /* margin-top: 63px; */
  /* min-height: calc(100vh - 63px); */
  min-height: 300px;
}

.centralized-avatar {
  justify-content: center;
  display: flex;
  align-items: center;
}

.extra-space {
  margin-bottom: 1rem;
}

/* .extra-space-remove {
    margin-top: -1em;
} */
.track-user-detail-card {
  margin-top: 20px;
}

.price-tag-detail {
  cursor: pointer;
  /* color: #00308F; */
}

.card-headertext-blue {
  color: #3894bb !important;
}
.text-black {
  color: #000 !important;
}
.text-black:hover {
  text-decoration: underline;
  color: #000 !important;
}

.centre-btn {
  margin-top: 20px;
  margin-left: 20px;
}

.driver-card {
  position: absolute;
  background-color: #fff;
  border: 1px solid #ccc;
  padding: 8px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.driver-card-new {
  position: relative;
  background-color: #000;
  /* border: 1px solid #ccc; */
  /* padding: 8px; */
  /* box-shadow: 0 0 5px rgba(0, 0, 0, 0.2); */
  z-index: 1;
  color: #fff;
}
.driver-card strong {
  display: block;
  margin-bottom: 5px;
}

/* CSS styles for circle-container */
.circle-container {
  list-style: none;
  display: flex;
  padding: 0;
  margin: 0;
}

/* CSS styles for circle */
.circle {
  width: 30px; /* Adjust the size as needed */
  height: 30px; /* Adjust the size as needed */
  border-radius: 50%;
  margin-right: 10px; /* Adjust the spacing between circles */
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px; /* Adjust the font size as needed */
  font-weight: bold;
  color: #fff; /* Adjust the text color */
}

.driver-card-2 {
  position: absolute;
  background-color: #fff;
  border: 1px solid #ccc;
  padding: 8px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
  z-index: 1;
  min-width: 10rem;
  top: 2rem;
}

.circle-pricing p {
  border: 3px double #f69b31;
  padding: 15px 0px;
  width: 110px;
  border-radius: 8px;
  text-align: center;
  font-size: 16px;
  font-weight: 600;
}

.pricing-modal-input {
  width: 10%;
}

.pricing-modal-input2 {
  width: 15%;
}

.pricing-modal-input3 {
  width: 30%;
}

.labeling-input {
  font-size: 12px;
  font-weight: 300;
  opacity: 1;
  top: 28px;
  left: 156px;
  position: absolute;
}

.input-wrapper {
  position: relative;
}

.pricing-modal-input4 {
  width: 100%;
  padding: 10px;
  /* border: 1px solid #ccc; */
  border-radius: 5px;
  font-size: 14px;
}

.pricing-modal-input4:focus + label,
.pricing-modal-input4:valid + label {
  top: -15px;
  left: 2px;
  font-size: 12px;
  /* color: #555; */
}

.label22 {
  position: absolute;
  top: 10px;
  left: 10px;
  transition: all 0.3s ease;
  pointer-events: none;
  /* color: #777; */
  font-size: 14px;
}

.vl {
  border-left: 6px solid #28a745;
  /* position: absolute; */
  /* left: 50%; */
  /* margin-left: -3px; */
  /* top: 0; */
}

.btn-height {
  height: 45px;
}

.text-14-new {
  font-size: 14px !important;
}

/* landing page css need to remove unuse */
@import url("https://fonts.googleapis.com/css?family=inter:300,400,500,600,700,800|Poppins:500,500,600,700,800,900");
/* div#navbarSupportedContent {
    justify-content: center;
}  */
.bg-dark {
  background-color: transparent !important;
}
/* #logsedf{
    width: 75%;
} */
/* .navbar-nav .nav-link {
    color: #000000;
    font-family: 'inter' , sans-serif;
    font-weight: 500;
    font-size: 14px;
    line-height: 1.7em;
    padding: 8px 25px !important;
    text-transform: uppercase;
} */
.navbar-brand {
  font-family: "inter", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  color: #000000;
  text-transform: uppercase;
}
.active-2,
.nav-link-2:hover {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  color: #28a2a9 !important;
  text-transform: uppercase;
}
.navbar-brand:hover {
  color: aqua;
}
.nav-right-content {
  margin: 0;
  padding: 0;
  list-style: none;
  margin-left: 15px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  gap: 10px;
  -webkit-transition: all 0.3s ease-in;
  transition: all 0.3s ease-in;
}
.nav-right-content .info-bar-item-two {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  gap: 10px;
  text-decoration: none;
}
.navtit {
  font-family: "inter", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  color: #283e4b;
  text-transform: uppercase;
  text-decoration: none;
}
.author-nav-content {
  background-color: #283e4b;
  color: #f69b31;
  padding: 5px 15px;
  border-radius: 6px;
  text-transform: uppercase;
  font-size: 14px;
  font-family: "inter", sans-serif;
  font-style: normal;
  font-weight: 500;
}

.banner-text {
  width: 60%;
  height: auto;
  margin: auto;
  text-align: center;
  margin-top: 410px;
}
#heaimg {
  width: 35%;
  height: auto;
}
.head-text p {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 40px;
  text-align: center;
  text-transform: uppercase;
  color: #ffffff;
}
.col-img-bg {
  background: #d9d9d9;
  box-shadow: 0px 0px 4px 3px rgba(0, 0, 0, 0.25);
  width: 40%;
  padding: 35px 20px;
  margin: auto;
  border-radius: 100px;
  margin-top: -40px;
}
#colimg {
  width: 80%;
  height: auto;
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.row-section {
  width: 100%;
  height: auto;
  padding-left: 40px;
  padding-right: 40px;
  padding-bottom: 40px;
}
.col-text {
  text-align: center;
}
.col-text h4 {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 39px;
  text-transform: uppercase;
  color: #293e4a;
  margin-top: 15px;
}
.col-text p {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 30px;
  text-align: center;
  color: #474747;
}
.feat-bg {
  background: #ffffff;
  box-shadow: 0px 0px 9px 7px rgba(0, 0, 0, 0.25);
  padding: 20px;
}
.feat-text {
  text-align: center;
}
.feat-text h3 {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 44px;
  line-height: 77px;
  text-transform: uppercase;
  color: #293e4a;
}
.feat-text p {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 190.52%;
  text-align: center;
  color: #474747;
}
.featuresrow-section {
  width: 100%;
  height: auto;
  padding: 40px;
}
.colbgfe {
  background: #d9d9d9;
  border-radius: 6px;
  padding: 10px;
  height: 400px;
  margin-bottom: 25px;
}
.col-fe-bg {
  background: #293e4a;
  box-shadow: 0px 0px 4px 3px rgba(0, 0, 0, 0.25);
  width: 40%;
  padding: 40px 20px;
  margin: auto;
  border-radius: 100px;
}
.colfe-text {
  text-align: center;
}
.colfe-text h4 {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 39px;
  text-transform: uppercase;
  color: #293e4a;
  margin-top: 15px;
}
.colfe-text p {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 25px;
  text-align: center;
  color: #474747;
}
.opt-section {
  width: 100%;
  height: auto;
  padding: 40px;
}
.optbg-col {
  background: #d9d9d9;
  border-radius: 6px;
  padding: 15px;
}
.opt-divs {
  display: flex;
}
.col-opt-bg {
  background: #293e4a;
  box-shadow: 0px 0px 4px 3px rgba(0, 0, 0, 0.25);
  width: 40%;
  padding: 46px 30px;
  margin: auto;
  border-radius: 100px;
  margin-right: 15px;
}
.colopt-text h4 {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 28px;
  line-height: 39px;
  /* identical to box height */
  color: #293e4a;
}
.colopt-text p {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 170.02%;
  color: #474747;
}
.redi-section {
  background: #fb951e;
  mix-blend-mode: multiply;
  padding: 40px;
  width: 100%;
  height: auto;
  margin-bottom: 8px;
}
.redi-text p {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 190.52%;
  color: #474747;
}
#imgredi {
  width: 85%;
  height: auto;
  margin: auto;
}
.redi-img {
  text-align: center;
}
.redi-text {
  margin-top: 15px;
}
.btn-secondary-2 {
  background: #293e4a;
  border-radius: 6px;
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 27px;
  text-transform: uppercase;
  color: #f69b31;
}
.btn-secondary-2:hover {
  background: #293e4a;
  border-radius: 6px;
  color: #f69b31;
}
.footer-section {
  background-color: #293e4a;
  width: 100%;
  height: auto;
  padding: 40px;
}
.footer-social ul {
  padding: 0px 0px;
}
.footer-social ul li {
  display: inline-block;
  margin: 0 7px;
  background: #f69b31;
  border-radius: 20px;
  color: #293e4a;
}
.footer-social ul li a {
  background: #f69b31;
  border-radius: 20px;
  color: #293e4a;
  font-size: 20px;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Inter", sans-serif;
}
#footerlog {
  width: 50%;
  height: auto;
}
.bannerslider-section {
  background: #293e4a;
  padding: 100px;
  margin-bottom: 8px;
  /* padding: 50px; */
  /* width: 100%; */
  height: auto;
}
.serhead-text h1 {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 70px;
  line-height: 158.52%;
  text-align: center;
  /* text-transform: uppercase; */
  color: #ffffff;
}
.footer-text h5 {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 39px;
  color: #fb951e;
}
.footer-menu ul {
  padding: 0px 15px;
}
.footer-menu ul li {
  list-style: none;
  padding: 5px 5px;
}
#foot-icon {
  color: #fb951e;
  font-size: 14px;
  font-weight: 400;
}
.footer-menu ul li a {
  color: #fff !important;
  text-decoration: none;
  font-size: 14px;
  font-weight: 400;
  font-family: "Inter", sans-serif;
  padding-left: 15px;
}
.footerb-text p {
  color: #000;
  text-align: center;
  font-family: "inter", sans-serif;
  font-size: 14px;
  font-weight: 400;
  padding-top: 10px;
}
.abo-section {
  width: 100%;
  height: auto;
  padding: 50px;
}
.abt-text h3 {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 34px;
  line-height: 158.52%;
  text-transform: uppercase;
  color: #293e4a;
}
.abt-text p {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 198.52%;
  color: #2d2d2d;
}
#imgabt {
  width: 80%;
  height: auto;
  margin: auto;
}
.abt-img {
  text-align: center;
}
.mgt-4 {
  margin-top: 1.5rem;
}
.faq-text h3 {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 26px;
  line-height: 158.52%;
  text-transform: uppercase;
  color: #293e4a;
}
.accordion-button {
  font-size: 16px;
  font-weight: 600;
  font-family: "inter", sans-serif;
  color: #474747;
}
.accordion-button:not(.collapsed) {
  color: #474747 !important;
  background-color: #d9d9d9 !important;
}
.did-find {
  background: #d9d9d9;
  padding: 30px;
}
.did-text {
  text-align: center;
}
.did-text h5 {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 158.52%;
  color: #293e4a;
}
.did-text p {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 158.52%;
  text-align: center;
  color: #000000;
}
.contact-section {
  width: 100%;
  height: auto;
  padding: 50px;
}
.cont-text h3 {
  text-align: center;
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 35px;
  line-height: 165.52%;
  color: #293e4a;
}
.cont-bg {
  background-color: #f69b31;
  padding: 30px;
}
.fomfelid {
  background: #efefef;
  box-shadow: 4px 4px 6px rgba(0, 0, 0, 0.25);
}
.sign-in {
  display: none;
}
.card-2 {
  /* background: #F69B31; */
  background: #143443;
  box-shadow: 0px 0px 6px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
}
.input-group-text {
  border-radius: 0rem !important;
  border-top-left-radius: 0.375rem !important;
  border-bottom-left-radius: 0.375rem !important;
}
.icon {
  padding: 8px;
  min-width: 10px;
  text-align: center;
}
.label-text {
  font-family: "inter", sans-serif;
  font-weight: 400;
  font-size: 14px;
  color: #283e4b;
}
#sigimg {
  width: 108%;
  height: auto;
}

@media screen and (min-width: 768px) and (max-width: 991px) {
  a.nav-link {
    margin-left: 0;
    font-size: 14px;
    font-family: "inter", sans-serif;
    color: #ffffff !important;
    height: 47px;
    padding: 23px 0;
    background-color: #333333 !important;
    text-align: center;
  }
  /* #logsedf{
        width: 55%;
    } */
  .nav-right-content {
    display: none;
  }
  .sign-in {
    display: unset;
  }
  .slider-section {
    width: 100%;
    height: 445px;
  }
  .head-text p {
    font-weight: 600;
    font-size: 14px;
    line-height: 25px;
    text-align: center;
  }
  .banner-text {
    width: 80%;
    height: auto;
    margin: auto;
    text-align: center;
    margin-top: 210px;
  }
  .col-img-bg {
    width: 60%;
    padding: 25px 20px;
    margin: auto;
    border-radius: 100px;
    margin-top: -23px;
  }
  #colimg {
    width: 100%;
    height: auto;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
  .col-text p {
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
  }
  .row-section {
    width: 100%;
    height: auto;
    padding-left: 25px;
    padding-right: 25px;
    padding-bottom: 25px;
  }
  .col-text h4 {
    font-weight: 700;
    font-size: 20px;
    line-height: 26px;
    margin-top: 15px;
  }
  .features-section {
    width: 100%;
    height: auto;
    padding: 30px;
  }
  .feat-text h3 {
    font-weight: 700;
    font-size: 33px;
    line-height: 50px;
  }
  .col-fe-bg {
    width: 50%;
    padding: 25px 20px;
  }
  .colfe-text p {
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
  }
  .col-opt-bg {
    width: 75%;
    padding: 27px 22px;
    margin-right: 15px;
  }
  .opt-section {
    width: 100%;
    height: auto;
    padding: 20px;
  }
  .colopt-text h4 {
    font-weight: 700;
    font-size: 18px;
    line-height: 30px;
  }
  .colopt-text p {
    font-weight: 500;
    font-size: 12px;
    line-height: 170.02%;
  }
  #imgredi {
    width: 100%;
    height: auto;
    margin: auto;
  }
  .redi-text p {
    font-weight: 600;
    font-size: 14px;
    line-height: 150.52%;
  }
  .redi-section {
    padding: 25px;
    width: 100%;
    height: auto;
  }
  .footer-social ul li {
    margin: 0px 2px;
  }
  .footer-section {
    width: 100%;
    height: auto;
    padding: 30px;
  }
  .bannerslider-section {
    background: #293e4a;
    /* padding: 55px;
        margin-bottom: 8px; */
    padding: 50px;
    width: 100%;
    height: auto;
  }
  .serhead-text h1 {
    font-weight: 700;
    font-size: 40px;
    line-height: 158.52%;
  }
  #imgabt {
    width: 100%;
    height: auto;
    margin: auto;
  }
  .abt-img {
    text-align: center;
    margin-top: 40px;
  }
  .abo-section {
    width: 100%;
    height: auto;
    padding: 30px;
  }
  .abt-text p {
    font-weight: 500;
    font-size: 14px;
    line-height: 170.52%;
  }
  .feat-text p {
    font-weight: 600;
    font-size: 14px;
    line-height: 190.52%;
  }
  .faq-section {
    width: 100%;
    height: auto;
    padding: 25px;
  }
  .faq-text h3 {
    font-weight: 700;
    font-size: 22px;
    line-height: 158.52%;
  }
  .did-find {
    background: #d9d9d9;
    padding: 15px;
  }
  .did-text h5 {
    font-weight: 600;
    font-size: 16px;
  }
  .did-text p {
    font-weight: 500;
    font-size: 14px;
    line-height: 158.52%;
  }
  .contact-section {
    width: 100%;
    height: auto;
    padding: 30px;
  }
  .sig-img {
    display: none;
  }
}

@media screen and (min-width: 0px) and (max-width: 768px) {
  .navbar .navbar-brand {
    width: 70%;
  }
  .navbar-expand-sm .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
    justify-content: center;
  }
  /* .navbar-dark .navbar-nav .nav-link {
        color: #fff !important;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        font-family: 'inter', sans-serif;
    } */
  a.nav-link {
    margin-left: 0;
    font-size: 14px;
    font-family: "inter", sans-serif;
    color: #ffffff !important;
    height: 37px;
    padding: 23px 0;
    background-color: #333333 !important;
    text-align: center;
  }
  .navbar-brand {
    font-weight: 300;
    font-size: 10px;
  }
  /* #logsedf{
        width: 60%;
    }  */
  .nav-right-content {
    display: none;
  }
  .sign-in {
    display: unset;
  }
  .serhead-text h1 {
    font-weight: 700;
    font-size: 28px;
    line-height: 158.52%;
    color: #ffffff;
  }
  .bannerslider-section {
    /* padding: 50px;
        margin-bottom: 8px; */
    padding: 50px;
    width: 100%;
    height: auto;
  }
  .contact-section {
    width: 100%;
    height: auto;
    padding: 25px;
  }
  .cont-text h3 {
    font-weight: 700;
    font-size: 26px;
    line-height: 165.52%;
  }
  .cont-bg {
    padding: 20px;
  }
  .footer-section {
    width: 100%;
    height: auto;
    padding: 25px;
  }
  .faq-section {
    width: 100%;
    height: auto;
    padding: 20px;
  }
  .feat-bg {
    padding: 12px;
  }
  .faq-text h3 {
    font-weight: 700;
    font-size: 18px;
    line-height: 158.52%;
  }
  .accordion-button {
    font-size: 14px;
    font-weight: 600;
  }
  .did-find {
    padding: 15px;
  }
  .abo-section {
    width: 100%;
    height: auto;
    padding: 25px;
  }
  #imgabt {
    width: 100%;
    height: auto;
    margin: auto;
  }
  .features-section {
    width: 100%;
    height: auto;
    padding: 20px;
  }
  .abt-text h3 {
    font-weight: 700;
    font-size: 28px;
    line-height: 158.52%;
  }
  .abt-text p {
    font-weight: 500;
    font-size: 14px;
    line-height: 198.52%;
  }
  .feat-text h3 {
    font-weight: 700;
    font-size: 28px;
    line-height: 35px;
  }
  .feat-text p {
    font-weight: 600;
    font-size: 14px;
    line-height: 190.52%;
  }
  .featuresrow-section {
    width: 100%;
    height: auto;
    padding: 20px;
  }
  #colimg {
    width: 100%;
    height: auto;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
  .col-fe-bg {
    width: 50%;
    padding: 40px 35px;
    margin: auto;
    border-radius: 100px;
  }
  .colfe-text h4 {
    font-weight: 700;
    font-size: 22px;
    line-height: 28px;
    color: #293e4a;
    margin-top: 15px;
  }
  .colfe-text p {
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
    text-align: center;
  }
  .opt-section {
    width: 100%;
    height: auto;
    padding: 15px;
  }
  .optbg-col {
    border-radius: 6px;
    padding: 8px;
    margin-top: 15px;
  }
  .colopt-text h4 {
    font-size: 20px;
    line-height: 39px;
  }
  .colopt-text p {
    font-weight: 500;
    font-size: 14px;
    line-height: 170.02%;
  }
  .col-opt-bg {
    width: 50%;
    padding: 40px 35px;
    margin: auto;
  }
  .opt-divs {
    display: block;
  }
  .optbg-col {
    border-radius: 6px;
    padding: 15px;
    margin-top: 15px;
  }
  .colopt-text {
    text-align: center;
  }
  .redi-section {
    background: #fb951e;
    mix-blend-mode: multiply;
    padding: 18px;
    width: 100%;
    height: auto;
    margin-bottom: 8px;
  }
  .redi-img {
    margin-top: 25px;
  }
  #imgredi {
    width: 100%;
    height: auto;
    margin: auto;
  }
  .redi-text p {
    font-weight: 600;
    font-size: 14px;
    line-height: 190.52%;
  }
  .slider-section {
    width: 100%;
    height: 210px;
  }
  .col-img-bg {
    width: 40%;
    padding: 35px 30px;
    margin: auto;
    margin-top: 0px;
  }
  .row-section {
    width: 100%;
    height: auto;
    padding: 20px;
  }
  .banner-text {
    width: 85%;
    height: auto;
    margin: auto;
    text-align: center;
    margin-top: 105px;
  }
  .banner-text {
    width: 85%;
    height: auto;
    margin: auto;
    text-align: center;
    margin-top: 105px;
  }
  .head-text {
    display: none;
  }
  .head-btn {
    margin-top: 20px;
  }
  #heaimg {
    width: 40%;
    height: auto;
  }
  .sig-img {
    display: none;
  }
  .py-5 {
    padding-top: 0rem !important;
    padding-bottom: 0rem !important;
  }
  .px-4 {
    padding-right: 0rem !important;
    padding-left: 0rem !important;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  /* .navbar-nav .nav-link {
        color: #333333;
        font-family: 'inter' , sans-serif;
        font-weight: 500;
        font-size: 14px;
        line-height: 1.7em;
        padding: 6px 11px !important;
    } */
  .slider-section {
    width: 100%;
    height: 640px;
  }
  .banner-text {
    width: 60%;
    height: auto;
    margin: auto;
    text-align: center;
    margin-top: 300px;
  }
  .col-img-bg {
    width: 40%;
    padding: 25px 16px;
    margin: auto;
  }
  .col-fe-bg {
    width: 44%;
    padding: 29px 15px;
  }
  .col-opt-bg {
    width: 80%;
    padding: 30px 20px;
    margin-right: 13px;
  }
  .colopt-text h4 {
    font-weight: 700;
    font-size: 24px;
    line-height: 39px;
  }
  .colopt-text p {
    font-weight: 500;
    font-size: 14px;
    line-height: 170.02%;
  }
  .colfe-text p {
    font-weight: 500;
    font-size: 14px;
    line-height: 23px;
  }
  .mgt-4 {
    margin-top: 0px;
  }
  .abt-text p {
    font-weight: 500;
    font-size: 14px;
    line-height: 198.52%;
  }
  .bannerslider-section {
    /* padding: 80px;
        margin-bottom: 8px; */
    padding: 50px;
    width: 100%;
    height: auto;
  }
  .serhead-text h1 {
    font-weight: 700;
    font-size: 40px;
    line-height: 158.52%;
  }
  #sigimg {
    width: 110%;
    height: auto;
  }
}
.btn-accept {
  color: #fff;
  background: #44cc44;
  padding: 2px 6px;
}
.btn-accept:hover {
  background: #8fe68f;
  box-shadow: 0 4px 0 0 #7ed37e;
}
.btn-deny {
  color: #fff;
  background: tomato;
  padding: 2px 6px;
  /* box-shadow: 0 4px 0 0 #CB4949; */
}
.btn-deny:hover {
  background: rgb(255, 147, 128);
  box-shadow: 0 4px 0 0 #ef8282;
}
.msg-box2 {
  /* padding-bottom: 0.5rem; */
  margin-bottom: 20px;
}

.table-space1 {
  width: 130px;
}

.bolder {
  font-weight: bold;
}

.chart-canvas {
  width: 90% !important;
  height: 60vh !important;
  /* max-width: 600px; */
}
.chart-canvas-bar {
  width: auto !important;
  /* height: 0vh !important; */
  /* max-width: 600px; */
}
.chart-canvas-pie {
  width: 100% !important;
  height: auto !important;
  /* max-width: 600px; */
}
.chart-canvas-radar {
  width: 100% !important;
  height: auto !important;
  /* max-width: 600px; */
}
/* Ensure minimum height for card bodies to prevent overlapping */
.card-body-new-2 {
  min-height: 240px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

/* Media query for smaller screens to adjust layout */
@media (max-width: 768px) {
  .chart-canvas {
    max-width: 100%;
    height: auto;
  }

  .card-body-new-2 {
    min-height: 320px; /* Increase min-height if necessary */
  }
}

.controls {
  position: absolute;
  bottom: 20px;
  right: 20px;
  background-color: rgba(255, 255, 255, 0.9);
  padding: 10px;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.search-bar {
  display: flex;
  align-items: center;
  margin-top: 20px;
}

.search-bar .form-control {
  width: 200px;
}

.gmnoprint.gm-style-mtc {
  padding: 0 69px 0px !important; /* Use !important to ensure it overrides the default styles */
}

.gm-style-iw-d {
  min-width: 300px !important;
}

/*  */

.map-sidebar {
  width: 300px;
  background-color: #f9f9f9;
  padding: 10px;
  border-right: 1px solid #ddd;
  overflow-y: auto;
  transition: transform 0.3s ease-in-out;
  position: relative;
  height: 100vh;
}

.map-sidebar.closed {
  transform: translateX(-100%);
}

.map-sidebar.open {
  transform: translateX(0);
}

.map-search-bar {
  width: 100%;
  padding: 8px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.map-asset-list {
  list-style-type: none;
  padding: 0;
}

.map-asset-item {
  margin-bottom: 15px;
  padding: 10px;
  background-color: #fff;
  border: 1px solid #eee;
  border-radius: 4px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.map-asset-item p {
  margin: 0;
  font-size: 14px;
}

.map-toggle-btn {
  position: absolute;
  top: 10px;
  left: 100%;
  transform: translateX(-50%);
  background-color: #007bff;
  color: white;
  padding: 5px 10px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  z-index: 1;
}

@media (max-width: 768px) {
  .map-sidebar {
    width: 100%;
    border-right: none;
    border-bottom: 1px solid #ddd;
  }

  .map-asset-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .map-toggle-btn {
    left: 90%; /* Adjust based on your preference */
  }
}

.min-width-orderId {
  min-width: 77px !important;
}

.showPassword {
  border-radius: 0rem !important;
  /* border-top-left-radius: 0rem !important;
    border-bottom-left-radius: 0rem !important; */
  border-top-right-radius: 0.375rem !important;
  border-bottom-right-radius: 0.375rem !important;
}

.custom-modal-content {
  margin: 12px 230px !important;
}

.blinking {
  animation: blink 1s infinite;
}

@keyframes blink {
  0%,
  50%,
  100% {
    opacity: 1;
  }
  25%,
  75% {
    opacity: 0.5;
  }
}

.booking-item .fa {
  font-size: 20px;
  margin-left: 8px;
}

.centralized-modal {
  display: flex !important;
  /* justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1050; */
}

.small-modal {
  width: 500px;
}

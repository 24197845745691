body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.accordion-header button {
  width: 100% !important;
  text-align: left !important;
  padding: 10px !important;
  font-size: 18px !important;
  font-weight: 400 !important;
}

.accordion-button:not(.collapsed) {
  color: black !important;
  background-color: #e8eef3 !important;
}

a {
  text-decoration: none !important;
}

.accordion-button::after {
  flex-shrink: 0;
  width: 10px;
  height: 10px;
  margin-left: auto;
  content: "";
  background-image: var(--bs-accordion-btn-icon);
  background-repeat: no-repeat;
  background-size: var(--bs-accordion-btn-icon-width);
  transition: var(--bs-accordion-btn-icon-transition);
}

.cursor-pointer {
  cursor: pointer !important;
}

@media (max-width: 991.98px) {
  .main-sidebar {
    transform: translate3d(-293px, 0, 0) !important;
  }

  .main-sidebar-overlay {
    position: absolute;
    width: 100vw;
    height: 100vh;
    z-index: 1;
    backdrop-filter: blur(2px);
  }

  .main-sidebar.open {
    transform: translate3d(0, 0, 0) !important;
  }
}

.call-popup {
  position: fixed;
  z-index: 10000;
  right: 20px;
  bottom: 20px;
  background: rgb(210, 206, 206);
  height: 350px;
  width: 280px;
  border-radius: 10px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  z-index: 10000;
}
.call-popup .header {
  width: 100%;
  background-color: #2d2c2a;
  color: white;
  padding: 5px 10px;
}
.call-popup .body {
  width: 100%;
  background-color: #2d2c2a;
  color: white;
  padding: 5px 10px;
}
.call-popup .title {
}
.call-popup .phone-icon {
}
.call-popup .phone-icon i {
  font-size: 20px;
}

.li-marker {
  height: 2px;
  width: 2px;
  background-color: black !important;
}
